  const AnswerArea = ({ showAnswer, answer, showAnswerBoxes }) => {

    // answer may be a value or JSX (in the case of the cube volume block which passes the answer plus superscript 3 for cubic cm)

    return (
        <>
            {showAnswerBoxes ?
            
                <div className="ma-answer-box-cont">
                    <div className="ma-answer-box"></div>
                </div>
            
            : null}

            {showAnswer ? <div className="ma-answer">Answer: {answer}</div> : null}
        </>
    )
  };

  export default AnswerArea;