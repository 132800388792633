import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

function romanize (num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {

    const num = randomInt(1, 1000);
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    data.answer = num;
    data.romanNumeral = romanize(num);

    updatedBlockData.data = data;
    return updatedBlockData;
};

const RomanNumeralsBlock = ({ data, showAnswerBoxes }) => {

    const { romanNumeral, answer } = data.data;

    return (

        <div className="romannumerals-cont">
            Convert the roman numerals into numerals.<br />
            <span>{romanNumeral}</span>
            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default RomanNumeralsBlock;