import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    
    data.layout = randomInt(1, 4);
    data.tileWidth = randomInt(2, 15);

    updatedBlockData.data = data;
    return updatedBlockData;
};

const CubeVolumeBlock = ({ data, showAnswerBoxes }) => {

    const { layout, tileWidth } = data.data;

    const answer = tileWidth;

    const layouts = [
        [
            [0, 1, 0],
            [1, 1, 1],
            [0, 1, 0],
            [0, 1, 0]
        ],
        [
            [0, 1, 0],
            [0, 1, 0],
            [1, 1, 1],
            [0, 1, 0]
        ],
        [
            [0, 1, 0, 0],
            [1, 1, 1, 1],
            [0, 1, 0, 0]
        ],
        [
            [0, 0, 1, 0],
            [1, 1, 1, 1],
            [0, 0, 1, 0]
        ]
    ];

    const tiles = layouts[layout-1];

    let lengthLabel;
    if(layout == 1 || layout == 2){
        lengthLabel = tileWidth * 4;
    } else {
        lengthLabel = tileWidth * 3;
    }

    // this block is unusual in that it can't just pass an answer value, needs to pass JSX for the superscript 3 for cubic cm
    const answerJSX = <>{answer} cm<sup>3</sup></>;

    // left arrow &#8592;
    // right arrow &#8594;
    // up 8593
    // down 8595

    return (

        <>
            {layout == 3 || layout == 4 ? <div>This is the net of a cube. What is the <strong>volume</strong> of the cube?</div> : null}

            <div className="ma-net-inner-cont">

                {layout == 1 || layout == 2 ? <div>This is the net of a cube. What is the <strong>volume</strong> of the cube?</div> : null}

                <div className={`ma-net-info-cont${(layout == 3 || layout == 4) ? ' ma-net-info-cont-short-height' : ''}`}>
                    <div className="ma-net-arrow ma-net-arrow-up">&#8593;</div>
                    <div className="ma-net-length">{lengthLabel} cm</div>
                    <div className="ma-net-arrow">&#8595;</div>
                </div>
                <div className="ma-net-cont">
                    {tiles.map((row, index) => {
                        return <div key={index} className="ma-tile-row">
                            {row.map((t, index2) => {
                                return <div key={index2} className={t === 1 ? 'ma-tile-on' : ''}></div>
                            })}
                        </div>;
                    })}
                </div>

                
            </div>
            {/* {data.showAnswer ? <div className="ma-answer">Answer: {answer} cm<sup>3</sup></div> : null} */ }
            <AnswerArea showAnswer={data.showAnswer} answer={answerJSX} showAnswerBoxes={showAnswerBoxes} />
        </>

    );

};

export default CubeVolumeBlock;