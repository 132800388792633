import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

// from https://www.parents.com/baby-names/ideas/short-baby-names-for-boys-and-girls/
/*
const arr = [];
document.querySelectorAll('ul.comp.mntl-sc-block li').forEach(li => {
    arr.push(li.textContent.trim());
});
console.log(arr)
*/
const personNames = ['Ann', 'Bea', 'Beth', 'Blaire', 'Claire', 'Dawn', 'Dee', 'Elle', 'Eve', 'Faye', 'Gail', 'Grace', 'Gwen', 'Jane', 'Jean', 'Joy', 'Kate', 'Kim', 'Liv', 'Madge', 'Paige', 'Pearl', 'Rose', 'Ruth', 'Sue', 'Tess', 'Aja', 'Bray', 'Drew', 'Gem', 'Gia', 'Jade', 'Kya', 'Lou', 'Luna', 'Minka', 'Quinn', 'Rain', 'Skye', 'Sloane', 'Star', 'Tia', 'Uma', 'Val', 'Zoe', 'Beau', 'Blake', 'Brock', 'Cade', 'Cale', 'Chad', 'Chase', 'Clark', 'Cole', 'Drake', 'Grant', 'Heath', 'Jack', 'Jake', 'Kent', 'Kurt', 'Luke', 'Max', 'Neil', 'Rhett', 'Ross', 'Todd', 'Trent', 'Troy', 'Vince', 'Ace', 'Ash', 'Beck', 'Chet', 'Dane', 'Dax', 'Finn', 'Gage', 'Grey', 'Guy', 'Hud', 'Jayce', 'Jax', 'Jude', 'Kai', 'Pax', 'Saul', 'Slade', 'Tate', 'Van', 'Vaughn', 'Zane'];


// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };

    data.singleUseGrams = randomInt(2,9) *10;
    data.totalGrams = data.singleUseGrams * (randomInt(2,9) *10);
    data.personName = personNames[Math.floor(Math.random() * personNames.length)];

    updatedBlockData.data = data;
    return updatedBlockData;
};

const DivisionWashingPowderBlock = ({ data, showAnswerBoxes }) => {

    const { totalGrams, singleUseGrams, personName } = data.data;
    const totalKg = totalGrams / 1000;



    const answer = totalGrams / singleUseGrams;

    return (

        <div>
            A box contains {totalKg} kg of washing powder.<br />
            {personName} uses {singleUseGrams} grams of powder for each wash. They use all the powder.
            How many washes did they do?
            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default DivisionWashingPowderBlock;