
const AddBlockForm = (props) => {

  const { addBlockFormClose, addBlockFormState, addBlockFormBlockList, addBlockFormSelectBlock, addBlockFormSubmit } = props;

  return (
    <div className="ma-modal-cont">
    <div style={{ display:'block' }} className="modal fade1" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Add Block</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => addBlockFormClose()}></button>
          </div>
          <div className="modal-body">
          
          {addBlockFormState.selectedBlock.key == '' ? (<>
          <div>Block type:</div>
          <ul>
          {addBlockFormBlockList.map((blockType, index) => {
            return (
              <li key={index}><button type="button" className="btn btn-outline-secondary" onClick={() => addBlockFormSelectBlock(blockType)}>{blockType.name}</button></li>
            );
          })}
          </ul>
          </>) : <div>Block type: {addBlockFormState.selectedBlock.name}</div>}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => addBlockFormClose()}>Close</button>
            <button type="button" className="btn btn-primary" onClick={addBlockFormSubmit}>Add</button>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default AddBlockForm;

