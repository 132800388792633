
//import React from 'react';
import Fraction from './Fraction';
import randomInt from '../Utils/randomInt'
import simplifyFraction from '../Utils/simplifyFraction'

import EmptySquare from '../Images/empty-square.svg';
import HalfSquare from '../Images/half-square.svg';
import FullSquare from '../Images/full-square.svg';

import AnswerArea from './AnswerArea';

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    data.rows = randomInt(1, 3);
    data.cols = randomInt(1, 3);
    data.maxParts = (data.rows * data.cols) * 2;
    data.partsToFill = randomInt(1, data.maxParts);

    updatedBlockData.data = data;
    return updatedBlockData;
};

const FractionBlock = ({ data, showAnswerBoxes }) => {

    // const rows = 2;
    // const cols = 3;
    //const rows = randomInt(1, 3);
    //const cols = randomInt(1, 3);

    const { rows, cols, maxParts, partsToFill } = data.data;

    const elements = [];

    const totalBlocks = rows * cols;
    //const maxParts = totalBlocks * 2;
    //onst partsToFill = randomInt(1, maxParts);

    const answerObj = simplifyFraction(partsToFill, maxParts);

    let partsFilled = 0;

    for(let i=0; i<rows; i++){
        const tempRow = [];
        for(let j=0; j<cols; j++){

            let blockImageCode = 0;
            if(partsFilled < partsToFill){
                // need to fill part(s)
                blockImageCode = (partsFilled + 2) > partsToFill ? 1 : 2;
            }

            tempRow.push(blockImageCode);
            partsFilled += 2; // may have only filled 1 part not 2 but doesn't matter at this point
        }
        elements.push(tempRow);
    }

    const BlockDiv = ({ code }) => {
        let blockImage = EmptySquare;

        if(code == 1){
            blockImage = HalfSquare;
        } else if(code == 2){
            blockImage = FullSquare;
        }

        return (
            <div className="box"><img alt="Icon" src={blockImage} /></div>
        )
    };

    const answerJSX = <Fraction whole={0} num={answerObj.num} denom={answerObj.denom} />;

    return (
        <div>
            What fraction is shaded?
            <div className="fraction-box-cont">
                {elements.map((row, rowIndex) => {

                    return (
                        <div key={rowIndex} className="box-row">
                            {row.map((blockImageCode, colIndex) => {
                                return <BlockDiv key={rowIndex+colIndex} code={blockImageCode} />
                             })}
                        </div>
                    );

                })}
            </div>
            { /*{data.showAnswer ? <div className="ma-answer">Answer: <Fraction whole={0} num={answerObj.num} denom={answerObj.denom} /></div> : null} */ }
            <AnswerArea showAnswer={data.showAnswer} answer={answerJSX} showAnswerBoxes={showAnswerBoxes} />
        </div>
    )
};

export default FractionBlock;