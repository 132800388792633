
const Fraction = ({ whole, num, denom }) => {

  return (<>
    {whole != 0 ? whole : null} <div className="frac">
    <span>{num}</span>
    <span className="symbol">/</span>
    <span className="denom">{denom}</span>
    </div>
  </>);
};

export default Fraction;