
const BlockCard = (props) => {

  let Block = props.block;
  let { blockData, index, showAnswer, refreshBlock, closeBlock, moveBlock, isFirst, isLast, widthMultiplier, showAnswerBoxes } = props;

  if(typeof widthMultiplier == 'undefined'){
    widthMultiplier = 1;
  }

  return (
    <div className={`col-sm-${3*widthMultiplier}`}>
      <div className={"ma-block ma-block-" + blockData.type}>
        <div className="ma-block-header">
          <span>{index+1}.</span>
          {isFirst == false ? <a href="#" title="Move left" onClick={(e) => { e.preventDefault(); moveBlock(blockData.id, 'left') }}><i className="bi-arrow-left"></i></a> : null}
          {isLast == false ? <a href="#" title="Move right" onClick={(e) => { e.preventDefault(); moveBlock(blockData.id, 'right') }}><i className="bi-arrow-right"></i></a> : null}
          <a href="#" title="Show Answer" onClick={(e) => { e.preventDefault(); showAnswer(blockData.id); }}><i className="bi-list-check"></i></a>
          <a href="#" title="Refresh" onClick={(e) => { e.preventDefault(); refreshBlock(blockData.id) }}><i className="bi-arrow-clockwise"></i></a>
          <a href="#" title="Close" onClick={(e) => { e.preventDefault(); closeBlock(blockData.id) }}><i className="bi-x-lg"></i></a>
        </div>
        <div className="ma-block-body">
          <Block data={blockData} showAnswerBoxes={showAnswerBoxes} />
        </div>
      </div>
    </div>
  );
};

export default BlockCard;

