
// Reduce a fraction by finding the Greatest Common Divisor and dividing by it.
const simplifyFraction = (numerator,denominator) => {
  var gcd = function gcd(a,b){
    return b ? gcd(b, a%b) : a;
  };
  gcd = gcd(numerator,denominator);
  return { num: numerator/gcd, denom: denominator/gcd };
};

export default simplifyFraction;