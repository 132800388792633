import randomInt from '../Utils/randomInt';
import numberWithCommas from '../Utils/numberWithCommas';

import AnswerArea from './AnswerArea';


// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    data.firstNumber = randomInt(100, 999);
    data.secondNumber = randomInt(10, 99);

    updatedBlockData.data = data;
    return updatedBlockData;
};

const MultiplyBlock = ({ data, showAnswerBoxes }) => {

    const { firstNumber, secondNumber } = data.data;

    const answer = numberWithCommas(firstNumber * secondNumber);

    return (

        <div className="multiply-cont">
            <div className="block-body">{firstNumber} &times; {secondNumber}</div>
            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default MultiplyBlock;